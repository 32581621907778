import { Channels, Genders } from '@slc-libs/enums';

import {
  BrandCategory,
  ContentCategory,
  HashtagCategory,
} from '@slice-interfaces/categories/category';

import { CreatorProfileConnectedAccount } from './connected-account';
import { CreatorProfilePrice } from './price';

export interface CreatorProfileAgency {
  agencyId: string;
  agencyName: string;
  picName: string;
  picEmail: string;
}

export interface CreatorProfile {
  userId: string;
  profileId: string;
  name: string;
  phone: string;
  address: string;
  avatar: string;
  biography: string;
  gender: Genders;
  birthDate: string;
  country: string;
  city: string;
  prices: Array<CreatorProfilePrice>;
  brands: Array<BrandCategory>;
  categories: Array<ContentCategory>;
  hashtags: Array<HashtagCategory>;
  connectedAccounts: Array<CreatorProfileConnectedAccount>;
  agencies?: Array<CreatorProfileAgency>;
  recentBrands?: Array<string>;

  /* additional appearance model */
  // appearanceSpokesPersonPrice?: number;
  // appearanceBrandAmbassadorPrice?: number;
  // appearanceEventAttendancePrice?: number;
  // appearancePhotoshootPrice?: number;
}

export interface PublicProfileAccount {
  id: string;
  channel: Channels;
  username: string;
  followersCount: number;
}

export interface UserPublicProfile {
  profileId: string;
  userId: string;
  name: string;
  avatar: string | undefined;
  categories: Array<ContentCategory>;
  biography: string;
  connectedAccounts: Array<PublicProfileAccount>;
}

export interface ProfileChangePasswordPayload {
  oldPass: string;
  newPass: string;
}
export interface ProfilePinChangePayload {
  oldPin: string;
  newPin: string;
}
export interface KycInterface {
  activeKycType?: CreatorKYCRole;
  // creator as personal
  idCardNumber?: string | null; // personal or company owner
  npwpNumber?: string | null;
  idCardImageUrl?: string | null; // personal or company owner
  npwpImageUrl?: string | null;
  creatorKycLevel?: CreatorKycLevel;
  selfieImageUrl?: string | null;

  // creator as company
  companyName?: string | null;
  companyNpwpNumber?: string | null;
  companyNpwpImageUrl?: string | null;
  companyDeedImageUrl?: string | null;
  creatorCompanyKycLevel?: CreatorKycLevel;

  // image assetId
  idCardImageAssetId?: string;
  npwpImageAssetId?: string;
  companyNpwpAssetId?: string;
  companyDeedAssetId?: string;
  selfieAssetId?: string | null;
}

export enum CreatorKYCRole {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export enum CreatorKycLevel {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  ID_CARD_SUBMITTED = 'ID_CARD_SUBMITTED',
  NPWP_SUBMITTED = 'NPWP_SUBMITTED',
  ALL_UPLOADED = 'ALL_UPLOADED',
  VERIFIED = 'VERIFIED',
}
