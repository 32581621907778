<button
  type="button"
  class="slc-btn slc-btn-outline-blue"
  [ngClass]="btnClass"
  (click)="showDialog()"
>
  <span>
    Metrics Description
    <slc-svg-icon-info-circle [hidden]="hideIcon"></slc-svg-icon-info-circle>
  </span>
</button>

<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  (onHide)="hideDialog()"
>
  <p-header>
    <img
      *ngIf="channel"
      [src]="'assets/images/metrics/' + channel + '.png'"
      alt="channel-logo"
    />
    <h6 class="font-weight-normal slc-mt-2">Metrics Descriptions</h6>
  </p-header>

  <div class="bg-white">
    <p-table
      [columns]="columnsMetrics"
      [value]="infoMetrics"
      [paginator]="false"
      [responsiveLayout]="'scroll'"
      [style]="{ width: '100%' }"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            *ngFor="let col of columns"
            [style.width]="col.width"
            [style.word-break]="'break-word'"
            [style.background-color]="'#f4f3fd'"
            slc-table-head-cell
            [column]="col"
          ></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td
            *ngFor="let col of columns"
            [style.width]="col.width"
            [style.word-break]="'break-word'"
          >
            <span [innerHTML]="rowData[col.field]"></span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="bg-white slc-mt-24">
    <p-table
      [columns]="columnsCost"
      [value]="infoCost"
      [paginator]="false"
      [responsiveLayout]="'scroll'"
      [style]="{ width: '100%' }"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            *ngFor="let col of columns"
            [style.width]="col.width"
            [style.word-break]="'break-word'"
            [style.background-color]="'#f1fef6'"
            slc-table-head-cell
            [column]="col"
          ></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td
            *ngFor="let col of columns"
            [style.width]="col.width"
            [style.word-break]="'break-word'"
          >
            <span [innerHTML]="rowData[col.field]"></span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!--<p-footer>
    Footer here
  </p-footer>-->
</p-dialog>
