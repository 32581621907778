import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AuthStateService } from '@slice-services/auth-state.service';
import { MixPanelService } from '@slice-services/mix-panel.service';
import { StripeService } from '@slice-services/stripe-http.service';
import { MessageService } from 'primeng/api';

import { BillingTypes, PlanTypes } from '@slc-libs/enums';
import { SubscriptionData, SubscriptionPlan } from '@slc-libs/interfaces';
import { PricingPlanUi } from '@slc-libs/interfaces';

import { MIX_PANEL_EVENTS } from '@slice-enums/mix-panel-events.enum';
import { NavigationService } from '@slice-services/navigation.service';

@Component({
  selector: 'slice-pricing-plan',
  templateUrl: './pricing-plan.component.html',
  styleUrls: ['./pricing-plan.component.scss'],
})
export class PricingPlanComponent {
  @Output() hide = new EventEmitter<void>();
  @Input() data: PricingPlanUi;
  @Input() billingType: BillingTypes;
  @Input() subsData: SubscriptionData;

  public PlanTypes = PlanTypes;
  public BillingTypes = BillingTypes;
  public loadingButton = false;

  constructor(
    private navigationS: NavigationService,
    private authStateS: AuthStateService,
    private stripeService: StripeService,
    private mS: MessageService,
    private tS: TranslocoService,
    private mpS: MixPanelService,
  ) {}

  showError(): void {
    this.mS.add({
      severity: 'error',
      life: 5 * 1000,
      detail: this.tS.translate('common.error-500'),
    });
  }

  selectPlan(selected: SubscriptionPlan): void {
    this.mpS.track(MIX_PANEL_EVENTS.agency_change_plan);
    let planPriceId: string | null = null;
    if (this.billingType && selected?.id) {
      planPriceId =
        this.billingType === BillingTypes.annual
          ? selected?.providerYearlyPriceId
          : selected?.providerMonthlyPriceId;
    }

    this.loadingButton = true;
    if (planPriceId) {
      if (
        this.authStateS.getAuthenticatedUser() &&
        this.authStateS.getAuthenticatedUser()?.subscriptionDefaultName ===
          PlanTypes.Free
      ) {
        // new subscription / payment flow
        this.stripeService
          .goToAgencyPayment({
            planId: selected?.id,
            // subsId: PLANS_IDS[environment.envType][PlanTypes.Standard],
            billingPeriod: this.billingType as any,
          })
          .subscribe(
            () => {
              this.loadingButton = false;
              this.hide?.emit();
            },
            () => {
              this.showError();
              this.loadingButton = false;
            },
          );
      } else if (
        this.authStateS.getAuthenticatedUser() &&
        this.authStateS.getAuthenticatedUser()?.subscriptionDefaultName !==
          PlanTypes.Free &&
        this.authStateS.getAuthenticatedUser()?.subscriptionDefaultName ===
          selected?.defaultName
      ) {
        // existing subscription / change billing period
        this.stripeService
          .goToAgencyUpgrade(
            selected?.defaultName,
            planPriceId,
            this.billingType as any,
          )
          .subscribe(
            () => {
              this.loadingButton = false;
              this.hide?.emit();
              this.mS.add({
                summary: 'Change Billing Period Successful',
                severity: 'success',
                life: 7 * 1000,
                detail: 'Your billing has been changed successfully',
              });
            },
            () => {
              this.showError();
              this.loadingButton = false;
            },
          );
      } else {
        // existing subscription / upgrade flow
        this.stripeService
          .upgradeAgencyPlan(selected?.defaultName, this.billingType as any)
          .subscribe(
            () => {
              this.loadingButton = false;
              this.hide?.emit();
            },
            () => {
              this.showError();
              this.loadingButton = false;
            },
          );
        // console.log('selectedPlan', selected);
        // alert('Selected Subscription is not available at the time');
      }
    } else {
      alert('subscriptionPlanPriceId is not found');
    }
  }
}
