import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { createHttpParams } from '@slc-libs/helpers';

import { environment } from '@slice-env/environment';

import { SubscriptionData } from '../../../../../libs/interfaces/src/lib/general-subscription.interface';

import { AuthStateService } from './auth-state.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralSubscriptionService {
  constructor(private http: HttpClient) {}

  fetchSubsPlan(): Observable<SubscriptionData> {
    return this.http.get<SubscriptionData>(`/api/public/v1/subcription-plan`);
  }
}
