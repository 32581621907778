import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'slice-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input() iconPath: string;
  @Input() text: string | undefined;
  @Input() modalTitle: string | undefined;
  @Input() tmpl: TemplateRef<any>;
  @Input() iconTmpl: TemplateRef<any>;
  @Input() okBtnClass: string;
  @Input() cancelBtnClass: string;
  @Input() okBtnKey: string;
  @Input() cancelBtnKey: string;
  @Input() isLoading: boolean;
  @Input() hideCancelBtn: boolean;
  @Input() disabledOkButton = false;

  public isVisible = true;

  @Output() closed = new EventEmitter<boolean>();
}
