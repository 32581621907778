import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AgencyProfile,
  AgencyProfilePayload,
} from '@slice-interfaces/agency-profile/agency-profile';
import { AuthenticatedUser } from '@slice-interfaces/auth/auth-user';
import {
  CreatorProfile,
  KycInterface,
  ProfileChangePasswordPayload,
  ProfilePinChangePayload,
  UserPublicProfile,
} from '@slice-interfaces/profile/creator-profile';
import { CreatorProfileUpdatePayload } from '@slice-interfaces/profile/user-full-data';

import { AuthStateService } from './auth-state.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileHttpService {
  constructor(private http: HttpClient, private authStateS: AuthStateService) {}

  fetchCreatorProfile(): Observable<CreatorProfile> {
    return this.http
      .get<CreatorProfile>(
        `/api/v1/users/${
          this.authStateS.getAuthenticatedUser()?.userId
        }/creator-profile`,
      )
      .pipe(
        map(profile => {
          if (profile && profile?.connectedAccounts) {
            this.authStateS.setCreatorConnectedAccounts(
              profile.connectedAccounts,
            );
          }
          return profile;
        }),
      );
  }

  fetchPublicProfile(): Observable<UserPublicProfile> {
    return this.http.get<UserPublicProfile>(
      `/api/v1/users/${
        this.authStateS.getAuthenticatedUser()?.userId
      }/public-profile`,
    );
  }

  patchCreatorProfile(
    body: CreatorProfileUpdatePayload,
  ): Observable<CreatorProfile> {
    return this.http.put<CreatorProfile>(
      `/api/v1/users/${
        this.authStateS.getAuthenticatedUser()?.userId
      }/creator-profile`,
      body,
    );
  }

  // agency

  private getAgencyId(): string {
    return this.authStateS.getAuthenticatedUser()?.agencyOwned as string;
  }

  fetchAgencyProfile(): Observable<AgencyProfile> {
    return this.http.get<AgencyProfile>(
      `/api/v1/agencies/${this.getAgencyId()}`,
    );
  }

  patchAgencyProfile(body: AgencyProfilePayload): Observable<AgencyProfile> {
    return this.http.put<AgencyProfile>(
      `/api/v1/agencies/${this.getAgencyId()}`,
      body,
    );
  }

  changePass(body: ProfileChangePasswordPayload): Observable<any> {
    return this.http.post<any>(`/api/v1/users/change-password`, body);
  }

  postPin(body: any): Observable<ProfilePinChangePayload> {
    return this.http.post<ProfilePinChangePayload>(
      `/api/v1/financial/basic/update-pin`,
      body,
    );
  }

  requestChangePhoneNumber(body: any): Observable<any> {
    return this.http.post<any>(
      `/api/v1/users/${
        this.authStateS.getAuthenticatedUser()?.userId
      }/wa-request-otp`,
      body,
    );
  }
  verifyChangePhoneNumber(body: any): Observable<any> {
    return this.http.post<any>(
      `/api/v1/users/${
        this.authStateS.getAuthenticatedUser()?.userId
      }/wa-verify-otp`,
      body,
    );
  }

  getCreatorKyc(id: string): Observable<KycInterface> {
    return this.http.get<KycInterface>(`/api/v1/users/${id}/creator-kyc`);
  }

  postKyc(id: string, body: KycInterface): Observable<any> {
    return this.http.put<KycInterface>(`/api/v1/users/${id}/creator-kyc`, body);
  }

  postEmailForgotPass(body: any): Observable<any> {
    return this.http.post<any>(
      `/api/v1/financial/basic/reset-pin/request`,
      body,
    );
  }
  postVerificationEmail(body: any): Observable<any> {
    return this.http.post<any>(
      `/api/v1/financial/basic/reset-pin/verify`,
      body,
    );
  }
  resetPin(body: any): Observable<any> {
    return this.http.post<any>(`/api/v1/financial/basic/reset-pin/`, body);
  }
}
