import { Component, OnDestroy, OnInit } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';

import { AppMenuStateService } from '@slice-services/app-menu-state.service';
import { AuthStateService } from '@slice-services/auth-state.service';
import { MixPanelService } from '@slice-services/mix-panel.service';
import { UiStateService } from '@slice-services/ui-state.service';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { BreakpointsService } from '@slc-libs/services';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { MenuItem } from '@slice-interfaces/navigation/menu-item';
import { UrlSegments } from '@slice-interfaces/routing';
import { CampaignModelStatus } from '@slice-enums/campaign-status';
import { MIX_PANEL_EVENTS } from '@slice-enums/mix-panel-events.enum';
import { E_ROUTES } from '@slice-enums/routes.enum';
import { UserTypes } from '@slice-enums/user-types.enum';
import { getRoutePath } from '@slc-libs/helpers';
import { ContractTypesEnum } from '@slice-enums/e-signature/xpress-contract.enum';
import { PAYMENT_HISTORY } from '@slice-enums/bulk-payment/campaign-enums';

@Component({
  selector: 'slice-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  public hiddenItemChilds: any = {};
  public stream$: Observable<{
    isDesktop: boolean;
    isMenuOpen: boolean;
    isMobile: boolean;
    isProKit: boolean;
  }>;
  public readonly ROUTES = E_ROUTES;
  public contactUsPath: UrlSegments;
  public isMenuOpen$: Observable<boolean>;
  public appVersion: string;
  public items$: Observable<Array<MenuItem>>;
  public bottomItems$: Observable<Array<MenuItem>>;
  public openingCampaignId: string | undefined;
  constructor(
    private router: Router,
    private mpS: MixPanelService,
    private bS: BreakpointsService,
    private uiStateS: UiStateService,
    private authStateS: AuthStateService,
    private appMenuStateS: AppMenuStateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.items$ = this.appMenuStateS.selectItems();
    this.bottomItems$ = this.appMenuStateS.selecBottomItems();
    this.contactUsPath = this.appMenuStateS.getContactUsPath();
    this.initAgencyCampaigns();
    this.initStream();
    this.initMenuItems();
    this.appMenuStateS.openingCampaignId$
      .pipe(takeUntil(this.destroy$))
      .subscribe(id => {
        this.openingCampaignId = id;
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public hasActiveChildRoute(item: MenuItem): boolean {       
    switch (item.route) {
      case E_ROUTES.REPORTS_HEADER:
        return (
          this.router.url.includes(E_ROUTES.REPORTS) ||
          this.router.url.includes(E_ROUTES.BULK_PAYMENT_CAMPAIGN) ||
          (this.router.url.includes(E_ROUTES.CONTRACT_SIGNATURE) &&
            this.router.url.includes(ContractTypesEnum.CAMPAIGN))
        );
      case E_ROUTES.DIRECT_PAYMENTS:
        return (
          this.router.url.includes(E_ROUTES.BULK_PAYMENT_CUSTOM_PAYMENT) ||
          (this.router.url.includes(E_ROUTES.CONTRACT_SIGNATURE) &&
            this.router.url.includes(ContractTypesEnum.STANDALONE))
        );
      case E_ROUTES.CREATOR_INFO:
        return (
          this.router.url.includes(E_ROUTES.CLIENT_ROSTER) ||
          (this.router.url.includes(E_ROUTES.BULK_PAYMENT_PAYMENT_HISTORY) &&
            this.router.url.endsWith(PAYMENT_HISTORY.earnings))
        );
      case E_ROUTES.TRANSACTION_RECORDS:
        return (
          this.router.url.includes(E_ROUTES.BULK_PAYMENT_BALANCE) ||
          (this.router.url.includes(E_ROUTES.BULK_PAYMENT_PAYMENT_HISTORY) &&
            this.router.url.endsWith(PAYMENT_HISTORY.payment))
        );
      default:
        return this.router.isActive(
          item.urlSegments?.join('/') as string,
          {
            paths: 'subset',
            fragment: 'ignored',
            queryParams: 'ignored',
            matrixParams: 'ignored',
          } as IsActiveMatchOptions,
        );
    }    
  }

  private initMenuItems(): void {
    this.authStateS
      .selectAuthenticatedUser()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(u => {
        if (u) {
          this.appMenuStateS.initMenu(u.userType);
          this.appMenuStateS.initBottomMenu(u.userType);
          this.appMenuStateS.actionUpdateByCampaigns();
        }
      });
  }

  private initAgencyCampaigns(): void {
    combineLatest([
      this.authStateS.selectAuthenticatedUser(),
      this.appMenuStateS.selectAgencyCampaigns(),
    ])
      .pipe(
        filter(([user]) => Boolean(user)),
        takeUntil(this.destroy$),
      )
      .subscribe(([user, campaigns]) => {
        this.appMenuStateS.updateMenuByCampaigns(
          user?.userType as UserTypes,
          campaigns,
        );
      });
  }

  private initStream(): void {
    this.stream$ = combineLatest([
      this.uiStateS.selectIsAppMenuOpen(),
      this.bS.selectIsDesktop(),
      this.bS.selectIsMobile(),
      this.authStateS
        .selectAuthenticatedUser()
        .pipe(map(u => !!u && u.userType === UserTypes.CREATOR)), // creator ProKit
    ]).pipe(
      map(([isMenuOpen, isDesktop, isMobile, isProKit]) => {
        if (isMenuOpen && !isDesktop) {
          document.body.classList.add('slice-body-not-scrollable');
        } else {
          document.body.classList.remove('slice-body-not-scrollable');
        }
        return { isMenuOpen, isDesktop, isMobile, isProKit };
      }),
    );
  }

  toggleShowMore(item: MenuItem): void {
    item.showAllItems = !item.showAllItems;
    if (item.showAllItems) {
      item.childs?.forEach(i => (i.isHidden = false));
    } else {
      item.childs?.forEach(
        i =>
          (i.isHidden = i.campaign?.status !== CampaignModelStatus.IN_PROGRESS),
      );
    }
  }

  showDataLoader(i?: MenuItem): void {
    if (i?.campaign?.campaignId) {
      this.appMenuStateS.openingCampaignId$.next(i.campaign.campaignId);
    }
  }

  mixpanelEventLog(i?: MenuItem): void {
    if (
      i &&
      i?.urlSegments &&
      i?.urlSegments.length > 0 &&
      this.authStateS.getAuthenticatedUser()?.userType
    ) {
      const event = i.urlSegments[i.urlSegments.length - 1];

      if (
        this.authStateS.getAuthenticatedUser()?.userType === UserTypes.AGENCY
      ) {
        switch (event) {
          case E_ROUTES.HOME:
            this.mpS.track(MIX_PANEL_EVENTS.agency_open_home_page);
            break;
          case E_ROUTES.CLIENT_ROSTER:
            this.mpS.track(MIX_PANEL_EVENTS.agency_open_roster_page);
            break;
          case E_ROUTES.DISCOVER_CREATORS:
            this.mpS.track(MIX_PANEL_EVENTS.agency_open_discovery_page);
            break;
          case E_ROUTES.REPORTS:
            this.mpS.track(MIX_PANEL_EVENTS.agency_open_reporting_list_page);
            break;
          default:
            // console.log('Mixpanel agency event not defined: ', event);
            break;
        }
      } else if (
        this.authStateS.getAuthenticatedUser()?.userType === UserTypes.CREATOR
      ) {
        switch (event) {
          case E_ROUTES.HOME:
            this.mpS.track(MIX_PANEL_EVENTS.creator_open_home_page);
            break;
          case E_ROUTES.OFFERS:
            this.mpS.track(MIX_PANEL_EVENTS.creator_open_roster_offer_page);
            break;
          case E_ROUTES.ANALYTICS:
            this.mpS.track(MIX_PANEL_EVENTS.creator_open_analytics_page);
            break;
          case E_ROUTES.RATE_CARD:
            this.mpS.track(MIX_PANEL_EVENTS.creator_open_mediakit_page);
            break;
          case E_ROUTES.WALLET_PAY_DETAIL:
            this.mpS.track(MIX_PANEL_EVENTS.creator_pay_detail_page);
            break;
          case E_ROUTES.WALLET_INVOICE:
            this.mpS.track(MIX_PANEL_EVENTS.creator_invoice_page);
            break;
          case E_ROUTES.WALLET_BALANCE:
            this.mpS.track(MIX_PANEL_EVENTS.creator_balance_page);
            break;
          default:
            // console.log('Mixpanel creator event not defined: ', event);
            break;
        }
      } else {
        /* do nothing here */
      }
    }
  }

  toggleMenu(i?: MenuItem): void {
    if (i) {
      if (!i.isBrand) {
        this.uiStateS.toggleMenu();
      }
    } else {
      this.uiStateS.toggleMenu();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  toggleChilds(i: MenuItem): void {
    if (i.isBrand) {
      this.hiddenItemChilds[i.id] = !this.hiddenItemChilds[i.id];
    }
  }
}
