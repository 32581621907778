import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { createHttpParams } from '@slc-libs/helpers';
import { getAgencyNameMock, getEmailMock } from '@slc-libs/mocks';

import { environment } from '@slice-env/environment';

import { AgencyClientsRosterFilters } from '@slice-interfaces/agency-client-roster/agency-client-roster-filters';
import { AgencyClientsRosterDetail } from '@slice-interfaces/agency-client-roster/agency-client-roster-item';
import { AgencyClientsRosterResponse } from '@slice-interfaces/agency-client-roster/agency-client-roster-response';
import { AgencyClientsRosterWidgetStats } from '@slice-interfaces/agency-client-roster/agency-client-roster-stat';
import { PublicAgencyClientsRosterFilters } from '@slice-interfaces/public-agency-client-roster/public-agency-client-roster-filters';
import { PublicAgencyClientsRosterResponse } from '@slice-interfaces/public-agency-client-roster/public-agency-client-roster-response';
import { PublicAgencyInfoResponse } from '@slice-interfaces/public-agency-client-roster/public-agency-roster-info-response';
import { PublicClientsRosterFilters } from '@slice-interfaces/public-client-roster/public-client-roster-filters';
import { PublicClientsRosterResponse } from '@slice-interfaces/public-client-roster/public-client-roster-response';

import { getAgencyClientsRosterResponseMocks } from './agency-client-roster-response.mock';
import { getPublicClientsRosterResponseMocks } from './client-roster-response.mock';
import { getPublicAgencyClientsRosterResponseMocks } from './public-agency-client-roster-response.mock';

@Injectable({
  providedIn: 'root',
})
export class ClientsRosterHttpService {
  constructor(private http: HttpClient) {}

  getClientsRoster(
    params: PublicClientsRosterFilters,
    useMock?: boolean,
  ): Observable<PublicClientsRosterResponse> {
    // mock
    const mocks = of(getPublicClientsRosterResponseMocks(params)).pipe(
      delay(1000),
    );
    const req = this.http.get<PublicClientsRosterResponse>(
      `/api/v1/todo-clients-roster`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  getAgencyClientsRoster(
    agencyId: string,
    params: AgencyClientsRosterFilters,
    useMock?: boolean,
  ): Observable<AgencyClientsRosterResponse> {
    // mock
    const mocks = of(getAgencyClientsRosterResponseMocks(params)).pipe(
      delay(1000),
    );
    const req = this.http.get<AgencyClientsRosterResponse>(
      `/api/v1/agencies/${agencyId}/invitations`,
      {
        params: createHttpParams(params),
      },
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  getPublicAgencyClientsRoster(
    agencyId: string,
    params: PublicAgencyClientsRosterFilters,
    useMock?: boolean,
  ): Observable<PublicAgencyClientsRosterResponse> {
    // mock
    const mocks = of(getPublicAgencyClientsRosterResponseMocks(params)).pipe(
      delay(1000),
    );
    const req = this.http.get<PublicAgencyClientsRosterResponse>(
      `/api/public/v1/agency/${agencyId}/creators`,
      {
        params: createHttpParams(params),
      },
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  fetchPublicAgencyRosterInfo(
    agencyId: string,
    useMock?: boolean,
  ): Observable<PublicAgencyInfoResponse> {
    // mock
    const mocks = of({
      agencyName: getAgencyNameMock(),
      email: getEmailMock(),
      picName: getEmailMock(),
      phone: '+380981111111',
    }).pipe(delay(1000));
    const req = this.http.get<PublicAgencyInfoResponse>(
      `/api/public/v1/agency/${agencyId}/roster`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  disconnectAgencyClientsRosterUser(
    agencyId: string,
    userId: string,
    useMock?: boolean,
  ): Observable<void> {
    // mock
    const mocks = of({} as any).pipe(delay(1000));
    const req = this.http.delete<void>(
      `/api/v1/agencies/${agencyId}/users/${userId}`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  getAgencyRosterStat(
    agencyId: string,
    useMock = false,
  ): Observable<AgencyClientsRosterWidgetStats> {
    const mocks = of({} as any).pipe(delay(1000));
    const req = this.http.get<AgencyClientsRosterWidgetStats>(
      `/api/v1/agencies/${agencyId}/rosters/stats`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  getAgencyRosterList(
    agencyId: string,
    params: AgencyClientsRosterFilters,
    useMock = false,
  ): Observable<AgencyClientsRosterResponse> {
    const mocks = of({} as any).pipe(delay(1000));
    const req = this.http.get<AgencyClientsRosterResponse>(
      `/api/v1/agencies/${agencyId}/rosters`,
      {
        params: createHttpParams(params),
      },
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  getAgencyRosterCRM(
    invitationId: string,
    useMock = false,
  ): Observable<AgencyClientsRosterDetail> {
    const mocks = of({} as any).pipe(delay(1000));
    const req = this.http.get<AgencyClientsRosterDetail>(
      `/api/v1/agencies/${invitationId}/rosters/detail`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  postAgencyRosterNotes(
    invitationId: string,
    body: { creatorNotes: string },
  ): Observable<any> {
    return this.http.post<any>(
      `/api/v1/agencies/${invitationId}/rosters/notes`,
      body,
    );
  }

  postAgencyRosterFavorites(
    invitationId: string,
    params: { favorited: boolean },
  ): Observable<any> {
    return this.http.post<any>(
      `/api/v1/agencies/${invitationId}/rosters/favorite`,
      {},
      {
        params: createHttpParams(params),
      },
    );
  }

  disconnectAgencyClientsRosterUserBulk(
    agencyId: string,
    body: { invitationIds: Array<String> },
  ): Observable<any> {
    return this.http.delete<any>(
      `/api/v1/agencies/${agencyId}/rosters`,
      { body },
    );
  }
}
