import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { RolePermissionService } from '@slice/role-user';
import { NavigationService } from '@slice-services/navigation.service';

import { E_ROUTES } from '@slice-enums/routes.enum';

@Injectable()
export class RolePermissionGuard implements CanActivateChild {
  constructor(
    private roleService: RolePermissionService,
    private navigationService: NavigationService,
    private router: Router,
  ) {}
  public canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    const url: string = state.url;
    return this.checkPermission(next, url);
  }

  private checkPermission(
    route: ActivatedRouteSnapshot,
    url: any,
  ): boolean | UrlTree {
    if (route.data && route.data.permission && route.data.permission.length) {
      for (const permission of route.data.permission) {
        if (this.roleService.checkUserPermission(permission)) {
          return true;
        }
      }

      console.log('invalid permission for', route.data.permission);
      return this.router.parseUrl(
        this.navigationService.getRoutePath(E_ROUTES.BASE),
      );
    } else {
      console.log('missing permission for', route.data)
      return this.router.parseUrl(
        this.navigationService.getRoutePath(E_ROUTES.BASE),
      );
    }
  }
}
