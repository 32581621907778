<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  (onHide)="hide()"
>
  <p-header>
    <div class="w-100">
      <h4 class="text-dark">{{ title }}</h4>
    </div>
  </p-header>
  <div>

    <div class="box glow-box" (click)="campaignPaymentClicked()">
      <div class="d-flex flex-row justify-content-between">
        <p class="text-dark box-title">
          Campaign Payments
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between mt-2">
        <p class="sub">
          Link your payments with contracts and reporting.
        </p>
      </div>
      <div class="divider"></div>
      <div class="d-flex flex-row justify-content-between">
        <p class="sub">
          Ideal for managing payments to a large number of micro or nano creators.
        </p>
      </div>
    </div>

    <div class="box glow-box" (click)="individualPaymentClicked()">
      <div class="d-flex flex-row justify-content-between">
        <p class="text-dark box-title">
          Direct Payments
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between mt-2">
        <p class="sub">
          Send money directly to bank account. 
        </p>
      </div>
      <div class="divider"></div>
      <div class="d-flex flex-row justify-content-between">
        <p class="sub">
          Ideal for paying a small number of mega or macro creators.
        </p>
      </div>
    </div>

  </div>
</p-dialog>
