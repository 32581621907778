import { Component, OnInit } from '@angular/core';

import { AppMenuStateService } from '@slice-services/app-menu-state.service';
import { AuthStateService } from '@slice-services/auth-state.service';
import { PageTitleService } from '@slice-services/page-title-service';
import { UiStateService } from '@slice-services/ui-state.service';
import { combineLatest, Observable } from 'rxjs';
import { delay, map, startWith, tap } from 'rxjs/operators';

import { PlanTypes, UserTypes } from '@slc-libs/enums';
import { BreakpointsService } from '@slc-libs/services';

import { DIALOG_RESULT_MODAL_TYPE } from '@slice-shared/components/dialog-result-modal/dialog-result-modal.component';

@Component({
  // for some libs must exist app-root component (Ang Univrrsal)
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public stream$: Observable<{
    isMobile: boolean;
    isDesktop: boolean;
    isMenuOpen: boolean;
    isAuthenticated: boolean;
    isHeader1440: boolean;
  }>;
  public pageWithAnimation: boolean;
  public isHeaderVisible: boolean;
  public pageTitle: string | undefined;
  public isAgencyBasicPlan: boolean;
  public isMenuVisible$: Observable<{ value: boolean }>;
  public isCampaignRedirectLoading$: Observable<boolean | undefined>;
  public showDialogResult$: Observable<any>;
  public showDialogPrice$: Observable<any>;
  public isModalRateCard$: Observable<boolean>;
  public isModalRateCard: boolean;

  constructor(
    private uiStateS: UiStateService,
    private authStateS: AuthStateService,
    private bS: BreakpointsService,
    private ptS: PageTitleService,
    private appMenuStateS: AppMenuStateService,
  ) {
    this.showDialogResult$ = this.uiStateS.selectDialogSliceModal();
    this.showDialogPrice$ = this.uiStateS.selectDialogAgencyPricing();
    this.isModalRateCard$ = this.uiStateS.selectModalRateCard();
    // this.uiStateS
    //   .selectModalRateCard()
    //   .subscribe(el => (this.isModalRateCard = el));
    // console.log(this.uiStateS.selectModalRateCard(), 'isModalRateCard');
  }

  ngOnInit(): void {
    this.isCampaignRedirectLoading$ = this.appMenuStateS.openingCampaignId$
      .asObservable()
      .pipe(map(r => Boolean(r)));
    this.uiStateS
      .selectIsHeaderVisible()
      .pipe(startWith(false), delay(0))
      .subscribe(value => {
        this.isHeaderVisible = value;
      });
    // this.isHeaderVisible$ = this.uiStateS.selectIsHeaderVisible();
    this.isMenuVisible$ = this.uiStateS
      .selectIsMenuVisible()
      .pipe(map(v => ({ value: v })));
    // this.pageTitle$ = this.ptS.selectPageTitle();
    this.ptS.selectPageTitle().subscribe(title => {
      this.pageTitle = title;
    });
    setTimeout(() => {
      this.pageWithAnimation = true;
    }, 1000);
    this.stream$ = combineLatest([
      this.bS.selectIsMobile(),
      this.bS.selectIsDesktop(),
      this.authStateS.selectIsAuthenticated(),
      this.uiStateS.selectIsAppMenuOpen(),
      this.uiStateS.selectIsHeader1440(),
    ]).pipe(
      map(
        ([isMobile, isDesktop, isAuthenticated, isMenuOpen, isHeader1440]) => {
          const res = {
            isMobile,
            isHeader1440,
            isDesktop,
            isMenuOpen,
            isAuthenticated,
          };
          return res;
        },
      ),
    );
    this.initAgencyPaymentDue();
  }

  initAgencyPaymentDue(): void {
    this.authStateS
      .selectAuthenticatedUser()
      .pipe(
        tap(authUser => {
          if (authUser && authUser.userType === UserTypes.AGENCY) {
            this.isAgencyBasicPlan =
              authUser.emailConfirmed &&
              authUser.subscriptionDefaultName === PlanTypes.Free;
          } else {
            this.isAgencyBasicPlan = false;
          }
        }),
      )
      .subscribe(user => {
        if (
          user &&
          user.emailConfirmed &&
          user?.userType === UserTypes.AGENCY &&
          user.paymentRequired
        ) {
          // show payment modal
          this.uiStateS.showSliceModal(DIALOG_RESULT_MODAL_TYPE.DUE);

          // mark onboard popup as displayed when agency have an upcoming payment
          this.authStateS.setOnboardDisplayed(true);
        }
      });
  }

  closeMenu(): void {
    this.uiStateS.closeAppMenu();
  }
}
