import { Component, OnInit } from '@angular/core';

import { NavigationService } from '@slice-services/navigation.service';

@Component({
  selector: 'slice-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss'],
})
export class PublicFooterComponent implements OnInit {

  constructor(
    private navigationService: NavigationService) {
  }

  ngOnInit(): void {
  }

  home(): void {
    this.navigationService.goToHome();
  }

  signup(): void {
    this.navigationService.goToSignUp();
  }
}
