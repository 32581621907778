<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="closed.emit(false)"
>
  <p-header> {{ modalTitle }} </p-header>

  <ng-container *ngIf="tmpl; else usualTmpl">
    <ng-template *ngTemplateOutlet="tmpl"></ng-template>
  </ng-container>
  <ng-template #usualTmpl>
    <ng-container *ngIf="iconTmpl">
      <ng-template [ngTemplateOutlet]="iconTmpl"></ng-template>
    </ng-container>
    <img *ngIf="iconPath" src="{{ iconPath }}" alt="img" />
    <p class="slc-mt-16" *ngIf="text">{{ text }}</p>
  </ng-template>

  <p-footer class="slc-flex-c-c">
    <button
      [hidden]="hideCancelBtn === true"
      class="{{ cancelBtnClass || 'slc-btn slc-btn-outline-grey' }}"
      (click)="closed.emit(false)"
      type="button"
    >
      <span>{{ cancelBtnKey ?? 'buttons.cancel' | transloco }}</span>
    </button>
    <button
      class="{{ okBtnClass || 'slc-btn slc-btn-primary' }}"
      (click)="closed.emit(true)"
      [ngClass]="{ 'slc-btn-loading': isLoading }"
      type="button"
      [disabled]="disabledOkButton"
    >
      <span>{{ okBtnKey ?? 'buttons.ok' | transloco }}</span>
    </button>
  </p-footer>
</p-dialog>
