import { Injectable } from '@angular/core';

import { LocalStorageService } from '@slice-services/local-storage.service';
import { UiStateService } from '@slice-services/ui-state.service';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthenticatedUser, WhatsNext } from '@slice-interfaces/auth/auth-user';
import { CreatorProfileConnectedAccount } from '@slice-interfaces/profile/connected-account';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);
  private authenticatedUser$ = new BehaviorSubject<AuthenticatedUser | null>(
    null,
  );
  private accessToken: string | undefined;
  private eligibleCreateReports = new BehaviorSubject<boolean>(true);
  private eligibleInviteCreators = new BehaviorSubject<boolean>(true);
  private eligibleInviteMembers = new BehaviorSubject<boolean>(true);
  private databaseReportTotal = new BehaviorSubject<number>(0);
  private databaseCreatorTotal = new BehaviorSubject<number>(0);
  private whatsNext = new BehaviorSubject<Array<WhatsNext>>([]);

  // creator connected account
  private connectedCreatorAccounts$ = new BehaviorSubject<
    Array<CreatorProfileConnectedAccount>
  >([]);

  constructor(
    private lsS: LocalStorageService,
    private uiStateS: UiStateService,
  ) {}

  selectIsAuthenticated(): Observable<boolean> {
    return this.isAuthenticated$.asObservable();
  }

  setAuthenticatedUser(d: AuthenticatedUser): void {
    console.log('USER TYPE - ', d.userType);
    this.authenticatedUser$.next(d);
    this.isAuthenticated$.next(true);
  }
  selectAuthenticatedUser(): Observable<AuthenticatedUser | null> {
    return this.authenticatedUser$.asObservable();
  }
  getAuthenticatedUser(): AuthenticatedUser | null {
    return this.authenticatedUser$.value;
  }

  setDefaultUserStatus(): void {
    const userStatus = this.lsS.getUserStatus();
    if (!userStatus) {
      this.lsS.setUserStatus({
        onboardFinished: false,
        onboardDisplayed: false,
        whatNextDisplayed: true,
        // eligiblePromo: false,
      });
    }
  }
  setUserStatusOnboard(profileCompleted: boolean): void {
    const userStatus = this.lsS.getUserStatus();
    this.lsS.setUserStatus({
      onboardFinished: profileCompleted,
      onboardDisplayed: false,
      whatNextDisplayed: userStatus?.whatNextDisplayed || true,
    });
  }
  isUserStatusNotExist(): boolean {
    const userStatus = this.lsS.getUserStatus();
    return !userStatus;
  }
  getUserStatusOnboard(): boolean {
    const userStatus = this.lsS.getUserStatus();
    return !userStatus?.onboardFinished && !userStatus?.onboardDisplayed;
  }
  setOnboardDisplayed(isFinished: boolean): void {
    const userStatus = this.lsS.getUserStatus();
    this.lsS.setUserStatus({
      onboardFinished: isFinished,
      onboardDisplayed: true,
      whatNextDisplayed: userStatus?.whatNextDisplayed,
    });
  }

  setWhatNextDisplayed(whatNextDisplayed: boolean): void {
    const userStatus = this.lsS.getUserStatus();
    this.lsS.setUserStatus({
      onboardFinished: userStatus?.onboardFinished || true,
      onboardDisplayed: userStatus?.onboardDisplayed || false,
      whatNextDisplayed: whatNextDisplayed,
    });
  }

  getWhatNext(): Array<WhatsNext> {
    return this.whatsNext?.value;
  }
  selectWhatNext(): Observable<Array<WhatsNext>> {
    return this.whatsNext.asObservable();
  }
  setWhatNext(val: Array<WhatsNext>): void {
    return this.whatsNext.next(val);
  }

  getDatabaseCreatorTotal(): number {
    return this.databaseCreatorTotal?.value;
  }
  selectDatabaseCreatorTotal(): Observable<number> {
    return this.databaseCreatorTotal.asObservable();
  }
  setDatabaseCreatorTotal(val: number): void {
    return this.databaseCreatorTotal.next(val);
  }

  getDatabaseReportTotal(): number {
    return this.databaseReportTotal?.value;
  }
  setDatabaseReportTotal(val: number): void {
    return this.databaseReportTotal.next(val);
  }

  getEligibleCreateReports(): boolean {
    return this.eligibleCreateReports?.value;
  }
  setEligibleCreateReports(val: boolean): void {
    return this.eligibleCreateReports.next(val);
  }

  getEligibleInviteCreators(): boolean {
    return this.eligibleInviteCreators?.value;
  }
  setEligibleInviteCreators(val: boolean): void {
    return this.eligibleInviteCreators.next(val);
  }

  getEligibleInviteMembers(): boolean {
    return this.eligibleInviteMembers?.value;
  }
  setEligibleInviteMembers(val: boolean): void {
    return this.eligibleInviteMembers.next(val);
  }

  getCreatorConnectedAccounts(): Array<CreatorProfileConnectedAccount> {
    return this.connectedCreatorAccounts$.value;
  }
  setCreatorConnectedAccounts(
    val: Array<CreatorProfileConnectedAccount>,
  ): void {
    return this.connectedCreatorAccounts$.next(val);
  }
  selectCreatorConnectedAccounts(): Observable<
    Array<CreatorProfileConnectedAccount>
  > {
    return this.connectedCreatorAccounts$.asObservable();
  }

  isEmailVerified(): boolean {
    return Boolean(this.authenticatedUser$.value?.emailConfirmed);
  }
  isAuthenticated(): boolean {
    return this.isAuthenticated$.value;
  }

  setAccessToken(at: string): void {
    this.accessToken = at;
  }
  getAccessToken(): string | undefined {
    return this.accessToken;
  }
  removeAccessToken(): void {
    this.accessToken = undefined;
  }

  logout(): void {
    this.uiStateS.closeSliceModal(true);
    this.uiStateS.setIsHeaderVisible(false);
    this.uiStateS.setIsMenuVisible(false);
    this.accessToken = undefined;
    this.lsS.clear();
    this.isAuthenticated$.next(false);
    this.authenticatedUser$.next(null);
    this.connectedCreatorAccounts$.next([]);
    // console.clear();
  }
}
