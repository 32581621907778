import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { AuthHttpService } from '@slice-services/auth-http.service';
import { GeographyHttpService } from '@slice-services/geography-http.service';
import { LocalStorageService } from '@slice-services/local-storage.service';
import { ProfileHttpService } from '@slice-services/profiles-http.service';
import { UiStateService } from '@slice-services/ui-state.service';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Channels } from '@slc-libs/enums';

import { environment } from '@slice-env/environment';

import { DIALOG_RESULT_MODAL_TYPE } from '@slice-shared/components/dialog-result-modal/dialog-result-modal.component';
import { OnboardingStep } from '@slice-interfaces/onboarding';
import {
  CreatorKYCRole,
  CreatorProfile,
} from '@slice-interfaces/profile/creator-profile';
import { CreatorProfileOnpoardingPayload } from '@slice-interfaces/profile/onboarding-payload';
import { CreatorProfilePrice } from '@slice-interfaces/profile/price';
import { Select } from '@slice-interfaces/select/select';
import { GeographyType } from '@slice-enums/geography';
import { OnboardingFormFields, OnboardingSteps } from '@slice-enums/onboarding';

import { parsePriceFormCtrlName } from '../../../../_helpers/price-form-control-name';
import { NewOnboardingStateService } from './onboarding-state.service';

@Injectable()
export class NewOnboardingFacadeService {
  private readonly FORM_FIELDS = OnboardingFormFields;
  constructor(
    private stateS: NewOnboardingStateService,
    private lsS: LocalStorageService,
    private httpS: AuthHttpService,
    private uiStateS: UiStateService,
    private geographyHttpS: GeographyHttpService,
    private authHttpS: AuthHttpService,
    private profileHttpS: ProfileHttpService,
  ) {}

  updateStateAndLocalStorage(): void {
    this.setPayload(this.createPayload());
    this.updateLocalStorage();
  }

  private updateLocalStorage(): void {
    this.lsS.setOnboardingData({
      steps: this.getSteps(),
      payload: this.getPayload(),
      connectedSocials: this.getConnectedSocials(),
    });
  }

  setNextStep(): void {
    const steps = this.getSteps();
    const currStep = this.getCurrStep() as OnboardingStep;
    const conncted = this.getConnectedSocials();

    if (
      !environment.isDemo &&
      currStep?.value === OnboardingSteps.YOUTUBE &&
      conncted?.length === 0
    ) {
      this.uiStateS.showSliceModal(DIALOG_RESULT_MODAL_TYPE.ERROR_SOCIAL);
    } else {
      currStep.isPassed = true;
      currStep.isTouched = true;
      currStep.isCurrent = false;
      const nextStep: OnboardingStep = steps?.find(
        i =>
          !i.isHidden &&
          typeof currStep?.order === 'number' &&
          i.order > currStep?.order,
      ) as OnboardingStep;
      nextStep.isCurrent = true;
      this.updateStepsState(steps as Array<OnboardingStep>);
      this.updateStateAndLocalStorage();
    }
  }

  setPrevStep(): void {
    const currStep = this.getCurrStep() as OnboardingStep;
    const steps = this.getSteps()?.filter(
      i => !i.isHidden,
    ) as Array<OnboardingStep>;
    const index = steps?.findIndex(i => i === currStep) as number;
    const prevIndex = index - 1;
    if (prevIndex >= 0) {
      steps[prevIndex].isCurrent = true;
      currStep.isCurrent = false;
      this.updateStepsState(steps as Array<OnboardingStep>);
      this.updateStateAndLocalStorage();
    }
  }

  setStep(s: OnboardingSteps): void {
    const steps = this.getSteps();
    const currStep = this.getCurrStep() as OnboardingStep;
    currStep.isPassed = true;
    currStep.isTouched = true;
    currStep.isCurrent = false;
    const newStep = this.getSteps()?.find(i => i.value === s) as OnboardingStep;
    newStep.isCurrent = true;
    this.updateStepsState(steps as Array<OnboardingStep>);
    this.updateStateAndLocalStorage();
  }

  updateStepsState(steps: Array<OnboardingStep>): void {
    const res = JSON.parse(JSON.stringify(steps)) as Array<OnboardingStep>;
    this.setSteps(res);
  }

  createOnboarding(): Observable<any> {
    return this.httpS
      .creatorOnboard(this.getPayload() as CreatorProfileOnpoardingPayload)
      .pipe(switchMap(() => this.authHttpS.me()));
  }

  createPayload(): CreatorProfileOnpoardingPayload {
    const mainForm = this.getMainForm();
    const accountData = mainForm.get(
      OnboardingSteps.ACCOUNT,
    ) as UntypedFormControl;
    const ratesFormGroup = mainForm.get(
      OnboardingSteps.RATE,
    ) as UntypedFormGroup;
    const prices: Array<CreatorProfilePrice> = Object.getOwnPropertyNames(
      ratesFormGroup.controls,
    )
      .filter(i => i !== OnboardingFormFields.isAtLeastOnePriceFilled)
      .map(formControlName => {
        const data = parsePriceFormCtrlName(formControlName);
        return {
          channel: data.channel,
          postType: data.postType,
          price: ratesFormGroup.get(formControlName)?.value,
        };
      });
    return {
      name: accountData.get(this.FORM_FIELDS.name)?.value,
      phone: accountData.get(this.FORM_FIELDS.phone)?.value?.e164Number,
      address: accountData.get(this.FORM_FIELDS.address)?.value,
      gender: accountData.get(this.FORM_FIELDS.gender)?.value,
      birthDate: accountData.get(this.FORM_FIELDS.birthDate)?.value,
      country: accountData.get(this.FORM_FIELDS.country)?.value,
      city: accountData.get(this.FORM_FIELDS.city)?.value,
      biography: accountData.get(this.FORM_FIELDS.biography)?.value || null,
      brands: accountData.get(this.FORM_FIELDS.brands)?.value,
      categories: accountData.get(this.FORM_FIELDS.categories)?.value,
      hashtags: accountData.get(this.FORM_FIELDS.hashtags)?.value,
      kycType: accountData.get(this.FORM_FIELDS.kycType)?.value,
      companyKycData:
        accountData.get(this.FORM_FIELDS.kycType)?.value ===
        CreatorKYCRole.COMPANY
          ? accountData.get(this.FORM_FIELDS.companyKycData)?.value
          : null,
      prices: prices,
    };
  }

  selectPayload(): Observable<CreatorProfileOnpoardingPayload | null> {
    return this.stateS.payload$.asObservable();
  }
  getPayload(): CreatorProfileOnpoardingPayload | null {
    return this.stateS.payload$.value;
  }
  setPayload(p: CreatorProfileOnpoardingPayload): void {
    this.stateS.payload$.next(p);
  }

  getCurrStep(): OnboardingStep | undefined {
    return this.stateS.steps$.value?.find(i => i.isCurrent);
  }
  selectSteps(): Observable<Array<OnboardingStep>> {
    return this.stateS.steps$.asObservable();
  }
  getSteps(): Array<OnboardingStep> | null {
    return this.stateS.steps$.value;
  }
  setSteps(list: Array<OnboardingStep>): void {
    this.stateS.steps$.next(list);
  }

  getMainForm(): UntypedFormGroup {
    return this.stateS.mainForm;
  }

  // replace to common service for countries ?
  fetchCountries(): void {
    this.geographyHttpS
      .getCountriesForSelect()
      .pipe(
        tap(r => this.setCountries(r)),
        switchMap(r => {
          return r.length === 1 ? this.fetchCities(r[0].value) : of([]);
        }),
      )
      .subscribe(cities => this.setCities(cities));
  }
  selectCountries(): Observable<Array<Select>> {
    return this.stateS.countries$.asObservable();
  }
  getCountries(): Array<Select> {
    return this.stateS.countries$.value;
  }
  setCountries(list: Array<Select>): void {
    this.stateS.countries$.next(list);
  }

  // replace to common service for countries ?
  fetchCities(countryUuid: string, name?: string): Observable<Array<Select>> {
    return this.geographyHttpS.getCitiesForSelect({
      parent: countryUuid,
      type: GeographyType.city,
      page: 0,
      size: 10,
      name: name,
    });
  }

  fetchCitiesNew(countryUuid: string, name?: string) {
    this.geographyHttpS
      .getCitiesForSelect({
        parent: countryUuid,
        type: GeographyType.city,
        page: 0,
        size: 10,
        name: name,
      })
      .subscribe(
        v => {
          this.setCities(v);
        },
        err => {
          throwError(err);
        },
      );
  }
  selectCities(): Observable<Array<Select>> {
    return this.stateS.cities$.asObservable();
  }
  getCities(): Array<Select> {
    return this.stateS.cities$.value;
  }
  setCities(list: Array<Select>): void {
    this.stateS.cities$.next(list);
  }

  setConnectedSocial(val: Channels): void {
    this.stateS.connectedSocials$.next([
      ...this.stateS.connectedSocials$.value,
      val,
    ]);
    this.updateStateAndLocalStorage();
  }
  getConnectedSocials(): Array<Channels> {
    return this.stateS.connectedSocials$.value || [];
  }
  setConnectedSocials(list: Array<Channels>): void {
    this.stateS.connectedSocials$.next(list);
  }

  selectConnectedSocial(): Observable<Array<Channels>> {
    return this.stateS.connectedSocials$.asObservable();
  }

  createSteps(): Array<OnboardingStep> {
    return [
      {
        value: OnboardingSteps.ACCOUNT,
        titleKey: 'onboarding.steps.accountSettings',
        order: 1,
        isPassed: false,
        isCurrent: true,
        isHidden: false,
        isTouched: true,
      },
      {
        value: OnboardingSteps.TIKTOK,
        titleKey: 'onboarding.step-tiktok.title',
        order: 2,
        isPassed: false,
        isCurrent: false,
        isHidden: false,
        isTouched: false,
      },
      {
        value: OnboardingSteps.INSTAGRAM,
        titleKey: 'onboarding.step-instagram.title',
        order: 3,
        isPassed: false,
        isCurrent: false,
        isHidden: false,
        isTouched: false,
      },
      {
        value: OnboardingSteps.YOUTUBE,
        titleKey: 'onboarding.step-youtube.title',
        order: 4,
        isPassed: false,
        isCurrent: false,
        isHidden: false,
        isTouched: false,
      },
      {
        value: OnboardingSteps.RATE,
        titleKey: 'onboarding.step-rate.title',
        order: 5,
        isPassed: false,
        isCurrent: false,
        isHidden: false,
        isTouched: false,
      },
      // {
      //   value: OnboardingSteps.PREVIEW,
      //   titleKey: 'onboarding.step-preview.title',
      //   order: 6,
      //   isPassed: false,
      //   isCurrent: false,
      //   isHidden: false,
      //   isTouched: false,
      // },
    ];
  }
  selectModalRateCard(): Observable<boolean> {
    return this.stateS.showModalRatecard.asObservable();
  }
  getModalRateCard(): boolean {
    return this.stateS.showModalRatecard.value;
  }
  setModalRateCard(d: boolean): void {
    this.stateS.showModalRatecard.next(d);
  }

  fetchProfileData(): Observable<CreatorProfile> {
    return this.profileHttpS
      .fetchCreatorProfile()
      .pipe(tap(profile => this.setProfile(profile)));
  }

  selectProfile(): Observable<CreatorProfile | null> {
    return this.stateS.profileData.asObservable();
  }
  getProfile(): CreatorProfile | null {
    return this.stateS.profileData.value;
  }
  setProfile(d: CreatorProfile): void {
    this.stateS.profileData.next(d);
  }
}
