<div class="row slc-mb-8">
  <p-dropdown
    [options]="dropdownCampaign"
    [(ngModel)]="selectedCampaign"
    (ngModelChange)="updateDropdown()"
    placeholder="Select Campaign"
    class="w-100"
    [style]="{ width: '100%' }"
    [showClear]="false"
    [disabled]="true"
    [filter]="true"
    filterBy="label"
    optionLabel="label"
  >
    <ng-template pTemplate="selectedItem">
      <div class="d-flex align-items-center">
        {{ selectedCampaign?.label }}
        <span class="ml-1 gray-text">
          {{ selectedCampaign?.value === '' ? ' (in past 90 days)' : '' }}
        </span>
      </div>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <div class="d-flex align-items-center">
        {{ item?.label }}
        <span class="ml-1 gray-text">
          {{ item?.value === '' ? ' (in past 90 days)' : '' }}
        </span>
      </div>
    </ng-template>
  </p-dropdown>

  <p-dropdown
    [options]="groupedPostType"
    [(ngModel)]="selectedGroupItem"
    (ngModelChange)="updateDropdown()"
    placeholder="Select Post Type"
    class="w-100 slc-mt-12"
    [style]="{ width: '100%' }"
    [group]="true"
    optionLabel="label"
  >
    <ng-template pTemplate="selectedItem">
      <div class="d-flex align-items-center" *ngIf="selectedChannel">
        <i [class]="'mr-2 pi pi-' + selectedChannel.toLowerCase()"></i>
        <span>
          {{ selectedChannel | transloco }} -
          {{ 'enums.post-types.' + selectedPost | transloco }}
        </span>
      </div>
    </ng-template>
    <ng-template let-group pTemplate="group">
      <div class="d-flex align-items-center" style="height: 18px">
        <i [class]="'ml-2 mr-2 pi pi-' + group.label.toLowerCase()"></i>
        <span>{{ group.label }}</span>
      </div>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <span class="slc-ml--32">{{ item.label }}</span>
    </ng-template>
  </p-dropdown>
</div>

<ng-container *ngIf="widgets$ | async; else emptyData">
  <ng-container *ngFor="let widgetList of widgets$ | async">
    <div class="row">
      <div
        *ngFor="let widget of widgetList"
        class="{{ gridMap[widgetList.length] }} slc-mt-12"
      >
        <slc-widget-simple
          class="slc-widget-shadow slc-p-16"
          [ngClass]="'col-magenta'"
          titleValue="{{ widget.titleKey | transloco }}"
          titleColored="{{ widget?.titleBlue | transloco }}"
          tooltipKey="{{ widget?.tooltipKey }}"
          [disableEllipsis]="true"
          [value]="
            widget.isPercentVal && widget.value
              ? (widget.value | number : '1.2-2') + '%'
              : (widget.value | number : '1.0-0')
          "
          [currency]="widget.currency"
        ></slc-widget-simple>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyData>
  <div class="row" *ngIf="selectedChannel && !isLoading">
    <i class="empty">Data Not Available</i>
  </div>
</ng-template>
