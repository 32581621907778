import { NgModule } from '@angular/core';

import { RangeDatePipe } from './date-range.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { NumberSuffixPipe } from './number-suffix.pipe';
import { RupiahPipe } from './rupiah.pipe';
import { SplitStringPipe } from './split-string.pipe';

@NgModule({
  declarations: [
    RangeDatePipe,
    LinkifyPipe,
    NumberSuffixPipe,
    RupiahPipe,
    SplitStringPipe,
  ],
  exports: [
    RangeDatePipe,
    LinkifyPipe,
    NumberSuffixPipe,
    RupiahPipe,
    SplitStringPipe,
  ],
})
export class AppPipesModule {}
