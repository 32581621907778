<ng-container [ngSwitch]="routeType">
  <slc-svg-icon-home *ngSwitchCase="ROUTES.HOME"></slc-svg-icon-home>
  <slc-svg-icon-users *ngSwitchCase="ROUTES.CREATOR_INFO"></slc-svg-icon-users>
  <slc-svg-icon-offers *ngSwitchCase="ROUTES.OFFERS"></slc-svg-icon-offers>
  <slc-svg-icon-calendar-1
    *ngSwitchCase="ROUTES.CONTENT_CALENDAR"
  ></slc-svg-icon-calendar-1>
  <slc-svg-icon-calendar-1
    *ngSwitchCase="ROUTES.CONTENT_APPROVALS"
  ></slc-svg-icon-calendar-1>
  <slc-svg-icon-payment
    *ngSwitchCase="ROUTES.PAYMENT_OPTIONS"
  ></slc-svg-icon-payment>
  <slc-svg-icon-payment *ngSwitchCase="ROUTES.DIRECT_PAYMENTS"></slc-svg-icon-payment>
  <slc-svg-icon-revenue *ngSwitchCase="ROUTES.REVENUE"></slc-svg-icon-revenue>
  <slc-svg-icon-report *ngSwitchCase="ROUTES.REPORTS_HEADER"></slc-svg-icon-report>
  <slc-svg-icon-invoicing
    *ngSwitchCase="ROUTES.INVOICING"
  ></slc-svg-icon-invoicing>
  <slc-svg-icon-brand *ngSwitchCase="ROUTES.PITCHING"></slc-svg-icon-brand>
  <slc-svg-icon-users *ngSwitchCase="ROUTES.CLIENTS"></slc-svg-icon-users>
  <slc-svg-icon-plane *ngSwitchCase="ROUTES.CAMPAIGNS"></slc-svg-icon-plane>
  <slc-svg-icon-media-kit
    *ngSwitchCase="ROUTES.RATE_CARD"
  ></slc-svg-icon-media-kit>
  <slc-svg-icon-invoicing
    *ngSwitchCase="ROUTES.TRANSACTION_RECORDS"
  ></slc-svg-icon-invoicing>
  <slc-svg-icon-analytics
    *ngSwitchCase="ROUTES.ANALYTICS"
  ></slc-svg-icon-analytics>
  <slc-svg-icon-search-bold
    *ngSwitchCase="ROUTES.DISCOVER_CREATORS"
  ></slc-svg-icon-search-bold>
  <slc-svg-icon-exco *ngSwitchCase="ROUTES.EXCO"></slc-svg-icon-exco>
  <slc-svg-icon-payment *ngSwitchCase="ROUTES.WALLET"></slc-svg-icon-payment>
  <slc-svg-icon-payment
    *ngSwitchCase="ROUTES.BULK_PAYMENT"
  ></slc-svg-icon-payment>
  <!--<slc-svg-icon-payment
    *ngSwitchCase="ROUTES.WALLET_BALANCE"
  ></slc-svg-icon-payment>
  <slc-svg-icon-payment
    *ngSwitchCase="ROUTES.WALLET_INVOICE"
  ></slc-svg-icon-payment>
  <slc-svg-icon-letter
    *ngSwitchCase="ROUTES.WALLET_PAY_DETAIL"
  ></slc-svg-icon-letter>-->
  <slc-svg-icon-building *ngSwitchCase="ROUTES.ACADEMY"></slc-svg-icon-building>
  <slc-svg-icon-plane
    *ngSwitchCase="ROUTES.CREATOR_CAMPAIGN"
  ></slc-svg-icon-plane>
  <slc-svg-icon-tools *ngSwitchCase="ROUTES.TOOLS"></slc-svg-icon-tools>
  <div *ngSwitchDefault></div>
</ng-container>
